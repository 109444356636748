.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 40%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
}
.image-text{
    text-align: center;
    font-size: 16px;
    font-weight: bold;    
    text-shadow: 0.1px 0.1px 0.5px #ffffff;
}